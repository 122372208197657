
$(document).on('click', '.close-image-popup', function(){
    $('.image-popup').fadeOut(300);
})

$(document).on('click', '.popup-img', function(){
    var elem = $(this),
        src = elem.attr('data-src');

    $('.image-popup').fadeIn(300).find('.image-wrapper').html('<img src="' + src + '">');
})
