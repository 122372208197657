$(document).ready(function () {
    
    $('.gallery-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        prevArrow: '<button class="btn with-bg icon icon-left-arrow prev"></button>',
        nextArrow: '<button class="btn with-bg icon icon-right-arrow next"></button>',
    })

    
    $(document).on('click', '.arrows[data-slider] div', function(){
        var elem = $(this),
            parent = elem.parent(),
            slider = parent.data('slider'),
            slider = $('.' + slider)[0].slider,
            index = elem.index();
        
        slider = parent.data('slider'),
        slider = $('.' + slider);

        if(index){
            slider.slick('slickNext');
        }else{
            slider.slick('slickPrev');
        }
    })

});



$('.first-slider').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 300,
    arrows: false,
    dots: false, 
    infinite: true,
    centerMode: false,
    autoplay: true,
    responsive: [{
        breakpoint: 768,
        settings: {
        centerMode: true,
        }},
        {
        breakpoint: 600,
        settings: {
        slidesToShow: 1,
        centerMode: true,
        }
    }]
  });
  $('.first-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
    $('span.current').text(currentSlide + 1);
  });
  

$('.projects-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 300,
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    responsive: [{
        breakpoint: 767,
        settings: {
        slidesToShow: 1,
        centerMode: false,
        }
    }
]
});


$('.services-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 300,
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    responsive: [{
        breakpoint: 767,
        settings: {
        slidesToShow: 1,
        centerMode: false,
        }
    }
]
});



$(document).on('click', '.toggle-content .tab', function(){
    var elem = $(this)
    var tabs = elem.siblings()
    var index = elem.index()
    var wrapper = elem.parents('.toggle-content')
    var contentWrapper = wrapper.children('.tabs-content')
    var content = contentWrapper.children()

    tabs.removeClass('active')
    elem.addClass('active')
    content.removeClass('active')
    content.eq(index).addClass('active')
})